import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { ButtonLinkProperties } from '@klokgroep/shared-components/src/Button/ButtonInterfaces';
import { LinkType, SanityImageType, SanityRichTextType, createHref } from '@klokgroep/sanity';
import { MediaItemsType, PreviewImageType } from '@klokgroep/sanity/src/queries/helpers/mediaItems';
import { PageHero } from '@klokgroep/shared-components/src/PageHero';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { ReactNode, useMemo } from 'react';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import Link from 'next/link';
import styles from './ProjectHero.module.css';

interface Properties {
  button?: ButtonLinkProperties;
  backLink?: { href?: LinkType; label?: string };
  carouselAutoplay?: boolean;
  images?: SanityImageType[];
  mediaItems?: MediaItemsType;
  previewImage?: PreviewImageType;
  intro?: SanityRichTextType;
  subMeta?: PillProperties[];
  meta?: PillProperties[];
  title?: string;
  forceBoxedHero?: boolean;
}
const ratio = { desktop: 16 / 8, mobile: 1 };

export const ProjectHero = ({
  button,
  backLink,
  carouselAutoplay,
  mediaItems,
  intro,
  meta = [],
  subMeta = [],
  title,
  forceBoxedHero,
}: Properties) => {
  // Render the backlink in this component, since the property of the pagehero just expects a string as href.
  const { locale } = useSiteInfo();
  const renderBackLink = useMemo(
    () => (backLink?.href ? { label: backLink.label, href: createHref({ ...backLink.href, locale }) } : undefined),
    [backLink, locale]
  );

  // In the project hero we do not want to show the title on the media items.
  const mediaItemsWithoutTitle = useMemo(
    () => mediaItems?.map((item) => ({ ...item, title: undefined })),
    [mediaItems]
  );

  return (
    <PageHero
      title={title}
      mediaItems={mediaItemsWithoutTitle}
      backLink={renderBackLink}
      carouselAutoplay={carouselAutoplay}
      contentMaxWidthSize="medium"
      ratio={ratio}
      forceBoxedHero={forceBoxedHero}>
      {meta?.length > 0 ? (
        <div className={styles.metaTags}>
          {meta.map(({ children, href }, index) => (
            <Pill key={`${href}-${index}`} href={href}>
              {children}
            </Pill>
          ))}
        </div>
      ) : undefined}

      {subMeta?.length > 0 ? (
        <div className={styles.metaTags}>
          {subMeta.map(({ children }, index) => (
            <div className={styles.subMeta} key={index}>
              {children}
            </div>
          ))}
        </div>
      ) : undefined}

      {intro ? (
        <div className={styles.introContainer}>
          <RichText>
            <PortableTextWithLinks content={intro} />
          </RichText>
        </div>
      ) : undefined}

      {button ? <ButtonLink {...button} /> : undefined}
    </PageHero>
  );
};

interface PillProperties {
  children: ReactNode;
  href?: string;
}

const Pill = ({ children, href }: PillProperties) =>
  href ? (
    <Link className={styles.pill} href={href}>
      {children}
    </Link>
  ) : (
    <span className={styles.pill}>{children}</span>
  );
