import { Fragment } from 'react';
import styles from './DictionaryList.module.css';

interface Properties {
  items: [string, string][];
}

export const DictionaryList = ({ items }: Properties) => (
  <dl className={styles.container}>
    {items.map(([term, definition]) => (
      <Fragment key={term}>
        <dt>{term}</dt>
        <dd>{definition}</dd>
      </Fragment>
    ))}
  </dl>
);
