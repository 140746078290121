import {
  createGetCorporateProjectQueryForSlug,
  createHref,
  getCorporateProjectForSlugAndSiteId,
  GetCorporateProjectForSlugResponseWithChildProjects,
  getCorporateProjectSlugsWithSiteIds,
  imageUrlFor,
  SiteIdTypeForCorporate,
} from '@klokgroep/sanity';
import { getRevalidateInterval } from '@klokgroep/shared-components/utils/getRevalidateInterval';
import {
  getPropertiesWithPreviewAndSitewideData,
  PropertiesWithPreviewNavigationAndSocialsAndMetaData,
} from '@klokgroep/shared-components/utils/getPropertiesWithPreviewNavigationAndSocials';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { CorporateContentModules } from '@klokgroep/shared-components/src/CorporateContentModules';
import { DictionaryList } from '@klokgroep/shared-components/src/DictionaryList';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { ProjectHero } from '@klokgroep/shared-components/src/ProjectHero';
import { ProjectsGrid } from '@klokgroep/shared-components/src/ProjectsGrid';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { Fragment, useMemo } from 'react';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { RelatedContentBlock } from '@klokgroep/shared-components/src/RelatedContentBlock';
import { VerticalMargin } from '@klokgroep/shared-components/src/VerticalMargin';
import { getNextJsRedirectObjectForSiteIdAndSlug } from '@klokgroep/shared-components/utils/getNextJsRedirectObjectForSiteIdAndSlug';
import { getFilteredRelatedItems } from '@klokgroep/shared-components/utils/getFilteredRelatedItems';
import { RelatedContentBlockProperties } from '@klokgroep/sanity/src/queries/blocks/relatedContent';

type Properties =
  PropertiesWithPreviewNavigationAndSocialsAndMetaData<GetCorporateProjectForSlugResponseWithChildProjects>;

const ProjectDetailPage: NextPage<Properties> = ({
  childProjects,
  corporateContentModules,
  corporateProjectHero,
  location,
  properties,
  title,
  url,
  relatedContent,
  parent,
}) => {
  const t = useCommonTranslations();
  const { theme, locale } = useSiteInfo();

  const meta = useMemo(() => {
    const { activities, concepts, features, locationType, status, workAreas } = properties || {};

    const propertyLabels = [status, locationType, workAreas, concepts, features, activities]
      ?.filter(Boolean)
      ?.flat()
      ?.map((item) => ({ children: item?.label }));

    return propertyLabels?.filter(({ children }) => Boolean(children));
  }, [properties]);

  const subMeta = useMemo(() => {
    const { buildingsAmount } = properties || {};

    return [{ children: buildingsAmount }]?.filter(({ children }) => Boolean(children));
  }, [properties]);

  const dictionaryItems = useMemo(() => {
    const { architect, constructorName, delivery, director, hideDelivery, installationConsultant, realization } =
      properties || {};

    return [
      [t('projects_detail_page_architect'), architect],
      [t('projects_detail_page_constructor'), constructorName],
      [t('projects_detail_page_director'), director],
      [t('projects_detail_page_installation_consultant'), installationConsultant],
      [t('projects_detail_page_realization'), realization],
      [t('projects_detail_page_delivery'), hideDelivery ? undefined : delivery],
    ]?.filter((item): item is [string, string] => !!item[1]);
  }, [properties, t]);

  const backLink = useMemo(
    () =>
      parent
        ? {
            href: { type: 'reference' as const, href: parent },
            label: t('back_to', { title: parent?.title?.replaceAll('*', '') }),
          }
        : {
            label: t('projects_detail_all_projects'),
            href: { type: 'reference', href: { _type: 'corporate-projectsOverviewPage' } },
          },
    [parent, t]
  );

  const uniqueRelatedContent = useMemo(() => {
    if (!relatedContent?.documentId) return relatedContent;
    const uniqueItems = getFilteredRelatedItems<RelatedContentBlockProperties['items']>(
      relatedContent.documentId,
      relatedContent?.items
    );

    return { ...relatedContent, items: uniqueItems };
  }, [relatedContent]);

  return (
    <section>
      <ProjectHero
        // cant figure out why the types don't match
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        backLink={backLink}
        carouselAutoplay={corporateProjectHero?.autoplay}
        mediaItems={corporateProjectHero?.mediaItems}
        intro={corporateProjectHero?.intro}
        subMeta={subMeta}
        meta={meta}
        title={`${title}, ${location}`}
      />
      <CorporateContentModules modules={corporateContentModules} isProjects />
      {(!!childProjects && childProjects?.length > 0) || (!!dictionaryItems && dictionaryItems?.length > 0) || url ? (
        <BlockContainerWithBackground smallPaddingTop>
          {!!childProjects && childProjects?.length > 0 ? (
            <MaxWidth size="medium">
              <RichText>
                <h2>{t('projects_detail_sub_projects')}</h2>
              </RichText>
              <ProjectsGrid columns={2} projects={childProjects} useBoxedCards={theme.includes('novaform')} />
            </MaxWidth>
          ) : undefined}

          {!!dictionaryItems && dictionaryItems?.length > 0 ? (
            <MaxWidth size="medium">
              <DictionaryList items={dictionaryItems} />
            </MaxWidth>
          ) : undefined}

          {url ? (
            <Fragment>
              <VerticalMargin />
              <MaxWidth size="medium">
                <ButtonLink
                  href={createHref({ externalLink: url, type: 'external', targetBlank: true, locale })}
                  target="_blank">
                  {t('projects_detail_view_project')}
                </ButtonLink>
              </MaxWidth>
            </Fragment>
          ) : undefined}
        </BlockContainerWithBackground>
      ) : undefined}

      {!!uniqueRelatedContent?.items && uniqueRelatedContent.items.length > 0 && (
        <RelatedContentBlock {...uniqueRelatedContent} />
      )}
    </section>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const data = await getCorporateProjectSlugsWithSiteIds();
  const paths = data
    ?.filter(({ siteId, slug }) => !!siteId && !!slug)
    ?.map(({ slug, siteId }) => ({ params: { slug, siteId } }));
  // BD: Hotfix temporary for corporate-paths: shorten build time by only generating 50 paths. The query orders the items on updatedAt, so the 50 most recent items are generated.
  const slicedPaths = paths.slice(0, paths.length > 50 ? 50 : paths.length);
  return { paths: slicedPaths, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<
  Properties,
  { slug: string; siteId: SiteIdTypeForCorporate },
  { token?: string }
> = async ({ preview = false, previewData, params }) => {
  if (!params?.slug || !params.siteId) {
    return { notFound: true, revalidate: getRevalidateInterval() };
  }

  const slug = params.slug.toString();

  const redirect = await getNextJsRedirectObjectForSiteIdAndSlug(params.siteId, `projecten/${slug}`);

  if (redirect) {
    return redirect;
  }

  const corporateProject = await getCorporateProjectForSlugAndSiteId(slug, params.siteId, preview);

  const dataForPreview = {
    params: { slug, siteId: params?.siteId },
    query: createGetCorporateProjectQueryForSlug(params.siteId, undefined, preview),
    token: previewData?.token,
  };

  const childProjectsArePresent = hasDefinedProperty(corporateProject);

  if (!corporateProject || !childProjectsArePresent) {
    return { notFound: true, revalidate: getRevalidateInterval() };
  }

  const properties = await getPropertiesWithPreviewAndSitewideData(
    preview,
    dataForPreview,
    corporateProject,
    {
      description: corporateProject.seo?.pageSeo?.description,
      image:
        corporateProject.seo?.pageSeo?.image ||
        (corporateProject.corporateProjectHero?.overviewImage
          ? imageUrlFor(corporateProject.corporateProjectHero?.overviewImage).url()
          : undefined) ||
        (corporateProject.corporateProjectHero?.mediaItems
          ? corporateProject.corporateProjectHero?.mediaItems[0]?._type == 'image'
            ? imageUrlFor(corporateProject.corporateProjectHero?.mediaItems[0]).url()
            : undefined
          : undefined),
      title: corporateProject.seo?.pageSeo?.title || corporateProject?.title,
      noIndex: corporateProject.seo?.pageSeo?.noIndex,
      schema: {
        socials: corporateProject.seo?.socials,
        organisation: {
          name: corporateProject.seo?.sitewideSeo?.organisationName,
          legalName: corporateProject.seo?.sitewideSeo?.organisationLegalName,
          description: corporateProject.seo?.sitewideSeo?.organisationDescription,
          founderName: corporateProject.seo?.sitewideSeo?.organisationFounder,
          foundingDate: corporateProject.seo?.sitewideSeo?.organisationFoundingDate,
          numberOfEmployees: corporateProject.seo?.sitewideSeo?.organisationNumberOfEmployees,
          logo: corporateProject.seo?.sitewideSeo?.organisationLogo,
          slogan: corporateProject.seo?.sitewideSeo?.organisationSlogan,
        },
      },
    },
    params.siteId
  );

  return {
    props: { ...properties, siteId: params.siteId },
    revalidate: getRevalidateInterval(),
  };
};

export default ProjectDetailPage;

function hasDefinedProperty(object: Record<string, unknown>) {
  for (const property in object) {
    if (Object.prototype.hasOwnProperty.call(object, property) && object[property] !== undefined) {
      return true;
    }
  }
  return false;
}
