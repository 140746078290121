import { ContentCard } from '@klokgroep/shared-components/src/ContentCard';
import { ContentCardBoxed } from '@klokgroep/shared-components/src/ContentCardBoxed';
import { GetCorporateProjectsResponse } from '@klokgroep/sanity';
import { SiteLabelPill } from '@klokgroep/shared-components/src/SiteLabelPill';
import { useMemo } from 'react';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import cx from 'classnames';
import styles from './ProjectsGrid.module.css';

interface Properties {
  columns?: number;
  projects: GetCorporateProjectsResponse[];
  useBoxedCards?: boolean;
}

export const ProjectsGrid = ({ columns = 3, projects, useBoxedCards = false }: Properties) => {
  const { siteId, theme } = useSiteInfo();
  const imageCrop = useMemo(() => (theme === 'novaform' ? 'square' : 'landscape'), [theme]);

  return (
    <div className={cx(styles.grid, { [styles.col2]: columns === 2, [styles.equalGap]: useBoxedCards })}>
      {projects?.map((project, index) => (
        <div className={styles.item} key={index}>
          {siteId === 'holding' && !!project.siteId && (
            <div className={styles.siteLabelContainer}>
              <SiteLabelPill siteId={project.siteId} />
            </div>
          )}
          {useBoxedCards ? (
            <ContentCardBoxed
              title={project.title}
              image={project.image}
              subText={project.location}
              link={{ type: 'reference', href: { slug: project.slug, _type: 'corporate-project' } }}
            />
          ) : (
            <ContentCard
              title={project.title}
              subText={theme == 'novaform' ? undefined : getSubtextForProject(project)}
              image={project.image}
              subTitle={project.location}
              imageCrop={imageCrop}
              link={{ type: 'reference', href: { slug: project.slug, _type: 'corporate-project' } }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

function getSubtextForProject({ properties }: GetCorporateProjectsResponse) {
  const { locationType, status, activities, workAreas, concepts, features } = properties || {};

  return [
    locationType ? locationType[0]?.label : undefined,
    status ? status[0]?.label : undefined,
    activities ? activities[0]?.label : undefined,
    workAreas ? workAreas[0]?.label : undefined,
    concepts ? concepts[0]?.label : undefined,
    features ? features[0]?.label : undefined,
  ]
    .filter(Boolean)
    .join(' • ');
}
